















































































import { CashBackService } from "@/core/services/geral";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class RelatorioCashback extends Vue {
    
    item: any = {};
    service = new CashBackService();

    filtro = {
        alunoId: 0
    }

    overlay: boolean = false;
    
    mounted() {
        this.filtro.alunoId = Number(this.$route.query.alunoId);
        this.Carregar();
    }

    VerificarValidade(validade: Date){

        if(new Date(validade).toYYYYMMDD() <= new Date().toYYYYMMDD()){
            return false;
        }
        else{
            return true;
        }
    }

    Carregar(){
        this.overlay = true;
        this.service.RelatorioAluno(this.filtro.alunoId).then(
            res =>{
                this.item  = res.data;                
            },
            err => {
                if (!err.response){
                    this.$swal("Aviso", "Não foi possível acessar a API", "error");
                }
                else{
                    this.$swal("Aviso",err.response.data,"error")
                }
            }
        ).finally(() => {
            this.overlay = false;
        })
    }
}
